.callTo{
   background-color: #fff !important;
}

.callTo1{
    z-index: 800000;
    position: fixed;
    bottom: 14px;
}

.callTo-message{
    font-size: 14px;
    color: #ef3f3e;
    font-weight: 700;
    font-family: Nunito, sans-serif;
    white-space: nowrap;
}

.callTo-message h6 {
    color: #ef3f3e;
    font-family:Nunito, sans-serif;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid #ef3f3e;; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    font-weight: 700;
 
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #ef3f3e }
  }
 
.callTo-message1{
  display: none !important;
}

.expanded{
    width: 200px !important;
    border-radius: 50px !important;
    display: inline-flex !important; 
    justify-content: space-evenly !important;
    background-color: #fff !important;
    transition: all 0.6s ease-in-out !important; 
}

  