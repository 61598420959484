.page{
    overflow-x: hidden;
}

.outstation-grid {
    display: block;
    /* grid-template-columns: repeat(4, 1fr);  */
    /* gap: 20px;  */
  }
  
  .outstation-grid > div {
    display: contents; /* Allow children to directly participate in the grid */
  }
  
  .heading {
    grid-column: span 4; /* Make the title span across all 4 columns */
    font-size: 27px;
    font-weight: bold;
    margin: 18px 0px;
    color: #ef3f3e;
  }
  
  .destination {
    padding: 0;
    list-style: none;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;

  }

  .destination li {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
  
  .arrow {
    margin-right: 8px;
    font-weight: bold;
    color: rgb(255, 184, 36);
  }
  
  a {
    text-decoration: none;
    color: black;
    font-size: 15px;
  }

  .link{
    color: black !important;
  }
  
  a:hover {
    text-decoration: underline;
    color: #ef3f3e;
  }

  @media (max-width : 767px) {
    .destination {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  
  @media (max-width:440px) {
    .destination {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
  